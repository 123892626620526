import React from "react";
import img1 from "../../../assets/img/affiliates/5/1.png";
import img2 from "../../../assets/img/affiliates/5/2.png";
import img3 from "../../../assets/img/affiliates/5/3.png";
import img4 from "../../../assets/img/affiliates/5/4.png";
import img5 from "../../../assets/img/affiliates/5/5.png";
import classes from "./Pack.module.scss";
import {useTranslation} from "react-i18next";
const Pack = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.pack}>
      <div className={classes.packBody}>
        <div className="container">
          <div className={[classes.packTitle, "simpleTitle"].join(" ")}>
            <span>{t('affiliates.pack_title')}</span>
          </div>
          <div className={classes.packSubtitle}>
            {t('affiliates.pack_text_1')}
          </div>
          <div className={classes.packSubtitle}>
            {t('affiliates.pack_text_2')}
          </div>
          <div className={classes.packRow}>
            <div className={classes.packRowColumn}>
              <div className={classes.packRowColumnImg}>
                <img src={img1} alt="" />
              </div>
              <div className={classes.packRowColumnSubtitle}>{t('affiliates.logo_files')}</div>
            </div>
            <div className={classes.packRowColumn}>
              <div className={classes.packRowColumnImg}>
                <img src={img2} alt="" />
              </div>
              <div className={classes.packRowColumnSubtitle}>
                {t('affiliates.static_banners')}
              </div>
            </div>
            <div className={classes.packRowColumn}>
              <div className={classes.packRowColumnImg}>
                <img src={img3} alt="" />
              </div>
              <div className={classes.packRowColumnSubtitle}>
                {t('affiliates.animated_banners')}
              </div>
            </div>
            <div className={classes.packRowColumn}>
              <div className={classes.packRowColumnImg}>
                <img src={img4} alt="" />
              </div>
              <div className={classes.packRowColumnSubtitle}>
                {t('affiliates.email_templates')}
              </div>
            </div>
            <div className={classes.packRowColumn}>
              <div className={classes.packRowColumnImg}>
                <img src={img5} alt="" />
              </div>
              <div className={classes.packRowColumnSubtitle}>
                {t('affiliates.information_sheet')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pack;
