import React from "react";
import classes from "./Would.module.scss";
import img from "../../../assets/img/would/1/1.png";
import {useTranslation} from "react-i18next";

const Would = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.would}>
      <div className="container">
        <div className={classes.wouldBody}>
          <div className={classes.wouldRow}>
            <div className={classes.wouldRowLeft}>
              <div className={[classes.wouldTitle, "simpleTitle"].join(" ")}>
                <span>{t('company.company_title')}</span>
              </div>
              <div className={classes.wouldSubtitle}>
                {t('company.company_text')}
              </div>
              <div className={classes.wouldText}>
                {t('company.company_white_text')}
              </div>
            </div>
            <div className={classes.wouldRowRight}>
              <img src={img} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Would;
