import React from "react";
import classes from './Cards.module.scss';
import i18next from "i18next";

const Cards = () => {
    return (
        <div className={classes.levels}>
            <div className="container">
                <div className={classes.levelsBody}>
                    <div className={classes.levelsRowWrap}>
                        <div className={classes.levelsRow}>
                            <div className={classes.levelsRowColumn}>
                                <div className={classes.levelsRowColumnTitle}>
                                    {i18next.t('tools.card_title_1')}
                                </div>
                                <div className={classes.levelsRowColumnSubtitle}>
                                    {i18next.t('tools.card_text_1')}
                                </div>
                            </div>
                            <div className={classes.levelsRowColumn}>
                                <div className={classes.levelsRowColumnTitle}>
                                    {i18next.t('tools.card_title_2')}
                                </div>
                                <div className={classes.levelsRowColumnSubtitle}>
                                    {i18next.t('tools.card_text_2')}
                                </div>
                            </div>
                            <div className={classes.levelsRowColumn}>
                                <div className={classes.levelsRowColumnTitle}>
                                    {i18next.t('tools.card_title_3')}
                                </div>
                                <div className={classes.levelsRowColumnSubtitle}>
                                    {i18next.t('tools.card_text_3')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cards;
