import React from "react";
import classes from './StartNow.module.scss';
import {useTranslation} from "react-i18next";
import Cookies from 'js-cookie';

const StartNow = () => {
  const { t } = useTranslation();
  const lang = Cookies.get('i18next');
  return (
    <div className={classes.startNow}>
      <div className={classes.header}>
        {t('tools.start_trading_now')}
      </div>
      <div className={classes.text}>
        {t('tools.start_trading_now_text')}
      </div>
      <div className={classes.btn}>
          <a href={`https://client.wealth-wise.one/signup?lang=${lang}`} className='btn-secondary'>{t('start_trading')}</a>
      </div>
    </div>
  );
};

export default StartNow;
