import React from "react";
import classes from "./First.module.scss";
import bg from "../../../assets/img/affiliates/1/1_mob.png";
import {useTranslation} from "react-i18next";

const First = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.first}>
      <div className="container">
        <div className={classes.firstBody}>
          <div className={[classes.firstTitle, "bigTitle"].join(" ")}>
            <span>{t('affiliates.banner_title')}</span>
          </div>
          <div className={classes.firstSubtitle}>
            {t('affiliates.banner_text')}
          </div>
        </div>
      </div>
      <div className={classes.firstMobImg}>
        <img src={bg} alt="" />
      </div>
    </div>
  );
};

export default First;
