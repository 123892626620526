import React from "react";
import classes from "./AboutAffiliates.module.scss";
import aboutAffiliates from "../../../assets/img/Index/about-affiliate-img.png";
import titleElipse from "../../../assets/img/Index/title-elipse-img.png";
import star from "../../../assets/img/Index/star.svg";
import {useTranslation} from "react-i18next";

const AboutAffiliates = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.aboutAffiliatesContainer}>
      <div className='container'>
        <div className={classes.aboutAffiliatesRow}>
          <div className={[classes.col7, classes.aboutAffiliatesWrap].join(' ')}>
            <h4 className={classes.aboutAffiliatesTitle}>
              {t('about_affiliates_title')}
              <img src={titleElipse} alt=""/>
            </h4>
              <p className={classes.aboutAffiliatesText}>
                {t('about_affiliates_text1')}
              </p>
              <p className={classes.aboutAffiliatesText}>
                {t('about_affiliates_text2')}
              </p>
            <div className={classes.aboutAffiliateList}>
              <div className={classes.aboutAffiliateItem}>
                <img src={star} alt=""/>
                <p>{t('about_affiliates_item1')}</p>
              </div>
              <div className={classes.aboutAffiliateItem}>
                <img src={star} alt=""/>
                <p>{t('about_affiliates_item2')}</p>
              </div>
              <div className={classes.aboutAffiliateItem}>
                <img src={star} alt=""/>
                <p>{t('about_affiliates_item3')}</p>
              </div>
              <div className={classes.aboutAffiliateItem}>
                <img src={star} alt=""/>
                <p>{t('about_affiliates_item4')}</p>
              </div>
              <div className={classes.aboutAffiliateItem}>
                <img src={star} alt=""/>
                <p>{t('about_affiliates_item5')}</p>
              </div>
            </div>
          </div>
          <div className={classes.col5}>
            <img src={aboutAffiliates} alt="" className="img-fluid"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutAffiliates
