import React from "react";
import classes from "./Hit.module.scss";
import {Trans} from "react-i18next";
import {useTranslation} from "react-i18next";
import Cookies from 'js-cookie';

const Hit = () => {
  const { t } = useTranslation();
  const lang = Cookies.get('i18next');
  return (
    <div className={classes.hit}>
      <div className="container">
        <div className={classes.hitBody}>
          <div className={classes.hitTitle}>
            <Trans i18nKey="affiliates.hit_title" components={{span: <span></span>}} />
          </div>
          <div className={classes.hitSubtitle}>
            {t('affiliates.hit_text')}
          </div>
          <a href={`https://client.wealth-wise.one/signup?lang=${lang}`} className={classes.hitBtn}>
            <span>{t('singUp')}</span>
            {/* <span>|</span>
            <span>{t('login')}</span> */}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hit;
