import React from "react";
import classes from './TheAdvantages.module.scss';
import star from '../../../assets/img/Tools/star.svg';
import {useTranslation} from "react-i18next";

const items = [
    'tools.the_advantages_item_1',
    'tools.the_advantages_item_2',
    'tools.the_advantages_item_3',
    'tools.the_advantages_item_4',
    'tools.the_advantages_item_5',
    'tools.the_advantages_item_6',
    'tools.the_advantages_item_7',
    'tools.the_advantages_item_8',
];

const TheAdvantages = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className={classes.advantages}>
                <div className={[classes.header, 'simpleTitle'].join(' ')}>
                    <span>{t('tools.the_advantages_title')}</span>
                </div>
                <div className={classes.content}>
                    {items.map((item, index) =>
                        <div className={classes.item} key={index}>
                            <img className="no-select" src={star} alt='' />
                            <div className={classes.text}>
                                {t(item)}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TheAdvantages;
