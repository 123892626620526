import React from "react";
import classes from './TradersAround.module.scss';
import img from '../../../assets/img/About/traders_around.png';
import {useTranslation} from "react-i18next";

const TradersAround = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className={classes.around}>
                <div className={classes.content}>
                    <div className={[classes.header, 'simpleTitle'].join(' ')}>
                        <span>{t('about.traders_around_title')}</span>
                    </div>
                    <div className={classes.text}>
                        <div>
                            {t('about.traders_around_text_1')}
                        </div>
                        <div className={classes.par}>
                            {t('about.traders_around_text_2')}
                        </div>
                    </div>
                    <div className={classes.second}>
                        {t('about.traders_around_list_title')}
                    </div>
                    <ul>
                        <li>{t('about.traders_around_item_1')}</li>
                        <li>{t('about.traders_around_item_2')}</li>
                        <li>{t('about.traders_around_item_3')}</li>
                    </ul>
                </div>
                <img className="no-select" src={img} alt='' />
            </div>
        </div>
    );
};

export default TradersAround;
