import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import classes from './BenefitTrading.module.scss';
import {Trans, useTranslation} from "react-i18next";

const BenefitTrading = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.registerPlugin(ScrollTrigger);
      if (ref !== null) {
        gsap.fromTo(
          document.querySelector('#benefitTrading'),
          {
            opacity: 0,
            y: 100,
          },
          {
            opacity: 1,
            y: 0,
            duration: .8,
            scrollTrigger: {
              trigger: document.querySelector('#benefitTrading'),
              start: "top bottom",
            }
          }
        );
      }
    }
  }, []);
  return (
    <div className={classes.benefitWrap} id='benefitTrading'>
      <div className='container'>
        <h3 className={classes.benefitTitle}><Trans i18nKey="benefit_title" components={{span: <span></span>}} /></h3>
        <div className={classes.benefitRowWrap}>
          <div className={classes.benefitRow}>
            <div className={classes.col}>
              <div className={classes.benefitItem}>
                <p>{t('benefit_item_1')}</p>
              </div>
            </div>
            <div className={classes.col}>
              <div className={classes.benefitItem}>
                <p>{t('benefit_item_2')}</p>
              </div>
            </div>
            <div className={classes.col}>
              <div className={classes.benefitItem}>
                <p>{t('benefit_item_3')}</p>
              </div>
            </div>
          </div>
          <div className={classes.benefitRow}>
            <div className={classes.col}>
              <div className={classes.benefitItem}>
                <p>{t('benefit_item_4')}</p>
              </div>
            </div>
            <div className={classes.col}>
              <div className={classes.benefitItem}>
                <p>{t('benefit_item_5')}</p>
              </div>
            </div>
            <div className={classes.col}>
              <div className={classes.benefitItem}>
                <p>{t('benefit_item_6')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.benefitBottomText}>
          {t('benefit_bottom_text')}
        </div>
      </div>
    </div>
  )
}

export default BenefitTrading;
