import React, { useState } from "react";
import img from "../../../assets/img/would/3/1.svg";
import classes from "./Trade.module.scss";
import {useTranslation} from "react-i18next";
const Trade = () => {
  const { t } = useTranslation();
  const [ name, setName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ phone, setPhone ] = useState('');
  const send = () => {
    if (name !== '' && email !== '' && phone !== '') {
      setName('');
      setEmail('');
      setPhone('');
    }
  };
  return (
    <div className={classes.trade}>
      <div className={classes.tradeBody}>
        <div className="container">
          <div className={classes.tradeRow}>
            <div className={classes.tradeRowLeft}>
              <div
                className={[classes.tradeRowLeftTitle, "simpleTitle"].join(" ")}
              >
                <span>{t('tools.trade_title')}</span>
              </div>
              <div className={classes.tradeRowLeftSubtitle}>
                {t('tools.trade_text')}
              </div>
            </div>
            <div className={classes.tradeRowRight}>
              <div className={classes.tradeRowRightForm}>
                <div className={classes.tradeRowRightFormTitle}>
                  {t('tools.trade_text_1')}
                </div>
                <div className={classes.tradeRowRightFormInput}>
                  <input value={name} onChange={(e) => setName(e.target.value)}  type="text" placeholder={t('form_full_name')} />
                </div>
                <div className={classes.tradeRowRightFormInput}>
                  <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder={t('form_email')} />
                </div>
                <div className={classes.tradeRowRightFormInput}>
                  <input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" placeholder={t('form_phone')} />
                </div>
                <button 
                  className={classes.tradeRowRightFormSend}
                  onClick={send}
                >
                  {t('form_send')} <img src={img} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trade;
