import React, { useEffect } from 'react';
import Banner from '../../components/Tools/Banner/Banner';
import Cards from '../../components/Tools/Cards/Cards';
import OurPlatform from '../../components/Tools/OurPlatform/OurPlatform';
import StartNow from '../../components/Tools/StartNow/StartNow';
import Straight from '../../components/Tools/Straight/Straight';
import TheAdvantages from '../../components/Tools/TheAdvantages/TheAdvantages';
import TradeAnywhere from '../../components/Tools/TradeAnywhere/TradeAnywhere';
import WhatToTrade from '../../components/Tools/WhatToTrade/WhatToTrade';
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';

const ToolsPage = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);
    return (
        <div className='ovf-hidden'>
            <Header />
            <Banner />
            <Straight />
            <WhatToTrade />
            <Cards />
            <OurPlatform />
            <TheAdvantages />
            <StartNow />
            <TradeAnywhere />
            <Footer />
        </div>
    );
};

export default ToolsPage;
