import React from "react";
import classes from './TradeAnywhere.module.scss';
import img from '../../../assets/img/Tools/trade_anywhere.png';
import {Trans, useTranslation} from "react-i18next";

const TradeAnywhere = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className={classes.streight}>
                <div className={classes.content}>
                    <div className={classes.header}>
                        <Trans i18nKey="company.trade_title" components={{br: <br />}} />
                    </div>
                    <div className={classes.text}>
                        <div>
                            {t('company.trade_text')}
                        </div>
                    </div>
                </div>
                <div className={classes.image}>
                    <img className="no-select" src={img} alt='' />
                </div>
            </div>
        </div>
    );
};

export default TradeAnywhere;
