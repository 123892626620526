import React from "react";
import classes from "./UtipPlatform.module.scss";
import googlePlayImg from "../../../assets/img/Index/google-play-download.png";
import appStoreImg from "../../../assets/img/Index/app-store-download.png";
import {useTranslation} from "react-i18next";

const UtipPlatform = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.posRel, 'container'].join(' ')}>
      <div className={classes.utipPlatformWrap}>
        <h5 className={classes.utipPlatformTitle}>{t('utip_platform_title')}</h5>
        <p className={classes.utipPlatformText}>{t('utip_platform_text')}</p>
        <div className={classes.utipPlatformBtnWrap}>
          <a href="#" className={classes.utipPlatformLink}>
            <img src={googlePlayImg} alt=""/>
          </a>
          <a href="#" className={classes.utipPlatformLink}>
            <img src={appStoreImg} alt=""/>
          </a>
        </div>
      </div>
      <div className={classes.utipPlatformLights}></div>
    </div>
  )
}

export default UtipPlatform
