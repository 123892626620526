import React from "react";
import classes from "./AboutUs.module.scss";
import {useTranslation} from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.aboutUsWrap}>
        <h3 className={classes.aboutUsTitle}>{t('about_us_title')}</h3>
        <p className={classes.aboutUsText}>
          {t('about_us_text')}
        </p>
        <p className={classes.aboutUsTextWhite}>{t('about_us_text_white')}</p>
      </div>
    </div>
  )
}

export default AboutUs;
