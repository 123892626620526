import React, { useState } from "react";
import classes from "./Popular.module.scss";
import {useTranslation} from "react-i18next";
const Popular = () => {
  const { t } = useTranslation();
  const [spoilers, setSpoilers] = useState([
    {
      id: 1,
      title: "spoilers_item_title_1",
      active: false,
      contents: [
        {
          id: 1,
          isStart: false,
          text: `spoilers_item_text_1`,
        },
      ],
    },
    {
      id: 2,
      title: "spoilers_item_title_2",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `spoilers_item_text_2_1`,
        },
        {
          id: 2,
          isStart: true,
          text: `spoilers_item_text_2_2`,
        },
      ],
    },
    {
      id: 3,
      title: "spoilers_item_title_1",
      active: false,
      contents: [
        {
          id: 1,
          isStart: false,
          text: `spoilers_item_text_3`,
        },
      ],
    },
  ]);

  const commissionsText = [
    {
      id: 1,
      name: "ties",
      price: 'per_label',
    },
    {
      id: 2,
      name: "ties",
      price: "per_label",
    },
    {
      id: 3,
      name: "ties",
      price: "per_label",
    },
    {
      id: 4,
      name: "ties",
      price: "per_label",
    },
    {
      id: 5,
      name: "ties",
      price: "per_label",
    },
  ];

  return (
    <div className={classes.popular}>
      <div className={classes.popularBody}>
        <div className="container">
          <div className={[classes.popularTitle, "simpleTitle"].join(" ")}>
            <span>{t('affiliates.popular_title')}</span>
          </div>
          {spoilers.map((i) => {
            return (
              <div key={i.id} className={classes.popularSpoiler}>
                <div
                  className={
                    i.active
                      ? [classes.popularSpoilerTitle, classes.active].join(" ")
                      : classes.popularSpoilerTitle
                  }
                  onClick={() => {
                    setSpoilers(
                      spoilers.map((curI) => {
                        if (curI.id === i.id) {
                          return { ...curI, active: !curI.active };
                        }
                        return { ...curI, active: false };
                      })
                    );
                  }}
                >
                  {t(`affiliates.${i.title}`)}
                </div>
                {i.contents.map((subI) => {
                  return (
                    <div
                      key={subI.id}
                      className={
                        i.active
                          ? subI.isStart
                            ? [
                                classes.popularSpoilerContent,
                                classes.active,
                                classes.star,
                              ].join(" ")
                            : [
                                classes.popularSpoilerContent,
                                classes.active,
                              ].join(" ")
                          : classes.popularSpoilerContent
                      }
                    >
                      {t(`affiliates.${subI.text}`)}
                    </div>
                  );
                })}
              </div>
            );
          })}
          {/* <div className={classes.popularSpoiler}>
            <div className={classes.popularSpoilerTitle}>
              How does the WealthWise Affiliate program work?
            </div>
            <div className={classes.popularSpoilerContent}>
              Everything is easy: it works like a mutually beneficial marketing
              program. You promote our broker marker by using your personal
              marketing tools using your personal link. When a new customer
              joins via your link and begins to trade with real money, you can
              start earning a commission. The more customers you attracted, the
              more chances to earn.
            </div>
          </div> */}

          <div className={classes.popularCommission}>
            <div className={classes.popularCommissionTitle}>
              {t('affiliates.popular_title_1')}
            </div>
            {commissionsText.map((i) => {
              return (
                <div className={classes.popularCommissionRowWrapper} key={i.id}>
                  <div className={classes.popularCommissionRow}>
                    <div className={classes.popularCommissionRowName}>
                      {t(`affiliates.${i.name}`)}
                    </div>
                    <div className={classes.popularCommissionRowPrice}>
                      {t(`affiliates.${i.price}`)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popular;
