import React from "react";
import classes from './Banner.module.scss';
import img from '../../../assets/img/Tools/banner.png';
import gs from '../../../assets/img/get_started.png';
import {useTranslation} from "react-i18next";
import Cookies from 'js-cookie';

const Banner = () => {
    const { t } = useTranslation();
    const lang = Cookies.get('i18next');
    return (
        <div className={classes.wrapper}>
            <div className={classes.banner}>
                <img className={classes.bannerImg} src={img} alt='' />
                <img className={classes.gs} src={gs} alt='' />
                <div className={classes.bannerContent}>
                    <div className={classes.header}>
                        {t('tools.banner')}
                    </div>
                    <div className={classes.text}>
                        {t('tools.banner_text')}
                    </div>
                    <div className={classes.buttons}>
                        <a href={`https://client.wealth-wise.one/login?lang=${lang}`} className='btn-default'>{t('live_account')}</a>
                        <a href={`https://client.wealth-wise.one/signup?lang=${lang}`} className='btn-default'>{t('demo_account')}</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
