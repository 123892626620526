import axios from "axios";
import React, { useEffect, useState } from "react";
import img1 from "../../../assets/img/would/2/1.png";
import img2 from "../../../assets/img/would/2/2.png";
import img3 from "../../../assets/img/would/2/3.png";
import img4 from "../../../assets/img/would/2/4.png";
import classes from "./Currency.module.scss";
import {useTranslation} from "react-i18next";
const Currency = () => {
  const { t } = useTranslation();
  const [bitcoin, setBitcoin] = useState("...");
  const [gold, setGold] = useState("...");
  const [euro, setEuro] = useState("...");
  const [pound, setPound] = useState("...");
  useEffect(() => {
    fetchBitcoin();
  }, []);

  async function fetchBitcoin() {
    try {
      const gold = await axios.get(
        `https://www.goldapi.io/api/XAU/USD/20200617`,
        {
          headers: {
            "x-access-token": "goldapi-1fngetlakwhjw0-io",
          },
          params: {},
        }
      );
      // console.log(data.USD.last);
      const { data } = await axios.get("https://blockchain.info/ticker");
      let curBtc = data.USD.last.toString();
      let resBtc = "$" + curBtc[0] + curBtc[1] + "," + curBtc.substr(2);
      setBitcoin(resBtc);

      let curGold = gold.data.price.toString();
      let resGold = "$" + curGold[0] + "," + curGold.substr(1);
      setGold(resGold);

      // const config = {
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      //   },
      // };

      const response = await axios.get(
        "https://api.tinkoff.ru/v1/currency_rates?from=EUR&to=USD"
      );
      setEuro("$" + response.data.payload.rates[2].buy.toFixed(2));

      const response1 = await axios.get(
        "https://api.tinkoff.ru/v1/currency_rates?from=GBP&to=USD"
      );
      setPound("$" + (response1.data.payload.rates[2].buy - 0.1).toFixed(2));
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className={classes.currency}>
      <div className={classes.currencyBody}>
        <div className="container">
          <div className={classes.currencyTitle}>
            {t('company.currency_title')}
          </div>
          <div className={classes.currencyRowWrapper}>
            <div className={classes.currencyRow}>
              <div className={classes.currencyRowColumn}>
                <div className={classes.currencyRowColumnName}>
                  <img src={img1} alt="" />
                  Bitcoin (BTC)
                </div>
                <div className={classes.currencyRowColumnPrice}>{bitcoin}</div>
                {/* <div className={classes.currencyRowColumnPercent}>-1.90%</div> */}
              </div>
              <div className={classes.currencyRowColumn}>
                <div className={classes.currencyRowColumnName}>
                  <img src={img2} alt="" />
                  Gold (XAU)
                </div>
                <div className={classes.currencyRowColumnPrice}>{gold}</div>
                {/* <div className={classes.currencyRowColumnPercent}>-1.90%</div> */}
              </div>
              <div className={classes.currencyRowColumn}>
                <div className={classes.currencyRowColumnName}>
                  <img src={img3} alt="" />
                  British pound (GBP)
                </div>
                <div className={classes.currencyRowColumnPrice}>{pound}</div>
                {/* <div className={classes.currencyRowColumnPercent}>-1.90%</div> */}
              </div>
              <div className={classes.currencyRowColumn}>
                <div className={classes.currencyRowColumnName}>
                  <img src={img4} alt="" />
                  Euro (EUR)
                </div>
                <div className={classes.currencyRowColumnPrice}>{euro}</div>
                {/* <div className={classes.currencyRowColumnPercent}>-1.90%</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Currency;
