import React, { useEffect } from "react";
import Header from "../../components/GlobalComponents/Header/Header";
import Banner from "../../components/Index/Banner/Banner";
import BenefitTrading from "../../components/Index/BenefitTrading/BenefitTrading";
import TradeBenefit from "../../components/Index/TradeBenefit/TradeBenefit";
import OnlineForex from "../../components/Index/OnlineForex/OnlineForex";
import EagleTrade from "../../components/Index/EagleTrade/EagleTrade";
import AboutAffiliates from "../../components/Index/AboutAffiliates/AboutAffiliates";
import UtipPlatform from "../../components/Index/UtipPlatform/UtipPlatform";
import HowToStart from "../../components/About/HowToStart/HowToStart";
import AboutUs from "../../components/Index/AboutUs/AboutUs";
import Footer from "../../components/GlobalComponents/Footer/Footer";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);
  return (
    <div>
      <Header />
      <Banner />
      <BenefitTrading />
      <TradeBenefit />
      <OnlineForex />
      <EagleTrade />
      <AboutAffiliates />
      <UtipPlatform />
      <HowToStart />
      <AboutUs />
      <Footer />
    </div>
  );
};

export default HomePage;
