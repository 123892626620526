import React, { useEffect, useState } from "react";
import classes from "./Header.module.scss";
import logo from "../../../assets/img/header/logo.svg";
import arrow from "../../../assets/img/header/arrow.svg";
import { headerRoutes } from "../../../config/routes/routes";
import { Link } from "react-router-dom";
import i18n from "i18next";
import Cookies from "js-cookie";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useTranslation } from "react-i18next";

const languages = [
  { value: "ru", label: "Ru" },
  { value: "en", label: "En" },
  { value: "es", label: "Es" },
  { value: "it", label: "It" },
  { value: "de", label: "De" },
];

const Header = () => {
  const { t } = useTranslation();
  const [localA, setLocalA] = useState(false);
  const [burgerA, setBurgerA] = useState(false);
  const [defaultOption, setDefaultOption] = useState(Cookies.get("i18next"));
  const lang = Cookies.get('i18next');
  useEffect(() => {
    setDefaultOption(Cookies.get("i18next"));
  }, []);
  return (
    <div className={classes.header}>
      <div className={classes.headerBody}>
        <div className="container">
          <div className={classes.headerRow}>
            <div className={classes.headerRowLogo}>
              <Link
                to="/"
                onClick={() => {
                  setBurgerA(false);
                }}
              >
                <img src={logo} alt="" />
              </Link>
            </div>
            <div
              className={
                burgerA
                  ? [classes.headerRowCenterWrapper, classes.active].join(" ")
                  : classes.headerRowCenterWrapper
              }
            >
              <div className={classes.headerRowCenter}>
                {headerRoutes.map((i) => (
                  <Link
                    key={i.text}
                    to={i.path}
                    className={classes.headerRowCenterItem}
                  >
                    {t(i.text)}
                  </Link>
                ))}
                <div className={classes.headerRowCenterMobile}>
                  {/* <p>{t("open_demo_account")}</p> */}
                  {/* <p>{t("open_live_account")}</p>
                  <p>{t("affiliate_program")}</p> */}
                </div>
              </div>
            </div>
            <div className={classes.headerRowRight}>
              <Dropdown
                options={languages}
                onChange={(e) => i18n.changeLanguage(e.value)}
                value={defaultOption}
                placeholder="EN"
              />
              <a href={`https://client.wealth-wise.one/login?lang=${lang}`} className={classes.headerRowRightLogBtn}>{t("login")}</a>
              <div
                className={
                  burgerA
                    ? [classes.headerRowRightBurger, classes.active].join(" ")
                    : classes.headerRowRightBurger
                }
                onClick={() => {
                  setBurgerA(!burgerA);
                }}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
