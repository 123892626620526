import React from "react";
import classes from './WhatToTrade.module.scss';
import img from '../../../assets/img/Tools/what_to_trade.png';
import {Trans} from "react-i18next";
import i18next from "i18next";

const WhatToTrade = () => {
    return (
        <div className="container">
            <div className={classes.streight}>
                <div className={classes.content}>
                    <div className={classes.header}>
                        <Trans i18nKey="tools.whatToTrade" components={{br: <br />}} />
                    </div>
                    <div className={classes.text}>
                        <div>
                            {i18next.t('tools.whatToTradeText1')}
                        </div>
                        <div className={classes.par}>
                            {i18next.t('tools.whatToTradeText2')}
                        </div>
                        <div className={classes.par}>
                            {i18next.t('tools.whatToTradeText3')}
                        </div>
                    </div>
                </div>
                <div className={classes.image}>
                    <img className="no-select" src={img} alt='' />
                </div>
            </div>
        </div>
    );
};

export default WhatToTrade;
