import React from "react";
import classes from "./Become.module.scss";
import {useTranslation} from "react-i18next";
const Become = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.become}>
      <div className="container">
        <div className={classes.becomeBody}>
          <div className={[classes.becomeTitle, "simpleTitle"].join(" ")}>
            <span>{t('affiliates.become_title')}</span>
          </div>
          <div className={classes.becomeSubtitle}>
            {t('affiliates.become_text')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Become;
