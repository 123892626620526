import React from 'react';
import classes from './WhyUs.module.scss';
import {useTranslation} from "react-i18next";

const WhyUs = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.levels}>
      <div className="container">
        <div className={classes.levelsBody}>
          <div className={[classes.levelsTitle, 'simpleTitle'].join(' ')}>
            <span>{t('about.why_us_title')}</span>
          </div>
          <div className={classes.levelsRowWrap}>
            <div className={classes.levelsRow}>
              <div className={classes.levelsRowColumn}>
                <div className={classes.levelsRowColumnTitle}>
                  {t('about.why_us_item_title_1')}
                </div>
                <div className={classes.levelsRowColumnSubtitle}>
                  {t('about.why_us_item_text_1')}
                </div>
              </div>
              <div className={classes.levelsRowColumn}>
                <div className={classes.levelsRowColumnTitle}>
                  {t('about.why_us_item_title_2')}
                </div>
                <div className={classes.levelsRowColumnSubtitle}>
                  {t('about.why_us_item_text_2')}
                </div>
              </div>
              <div className={classes.levelsRowColumn}>
                <div className={classes.levelsRowColumnTitle}>
                  {t('about.why_us_item_title_3')}
                </div>
                <div className={classes.levelsRowColumnSubtitle}>
                  {t('about.why_us_item_text_3')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
