import React from "react";
import classes from "./EagleTrade.module.scss";
import Cards from "../../Tools/Cards/Cards";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

const EagleTrade = () => {
  const { t } = useTranslation();
  const lang = Cookies.get('i18next');
  return (
    <div className={classes.eagleTradeContainer}>
      <div className="container">
        <h3 className={classes.eagleTradeTitle}>{t("eagle_trade_title")}</h3>
        <Cards />
        <div className={classes.eagleTradeTable}>
          <div className={classes.eagleTradeColumn}>
            <p className={classes.eagleTradeTableLabel}>
              {t("account_currency")}
            </p>
            <p className={classes.eagleTradeTableText}>USD, GBP, EUR, BTC</p>
          </div>
          <div
            className={[
              classes.eagleTradeColumn,
              classes.eagleTradeColumnBlack,
            ].join(" ")}
          >
            <p className={classes.eagleTradeTableLabel}>
              {t("minimum_deposit")}
            </p>
            <p className={classes.eagleTradeTableText}>250 USD</p>
          </div>
          <div className={classes.eagleTradeColumn}>
            <p className={classes.eagleTradeTableLabel}>{t("leverage")}</p>
            <p className={classes.eagleTradeTableText}>1:500</p>
          </div>
          <div
            className={[
              classes.eagleTradeColumn,
              classes.eagleTradeColumnBlack,
            ].join(" ")}
          >
            <p className={classes.eagleTradeTableLabel}>{t("spread")}</p>
            <p className={classes.eagleTradeTableText}>{t("floating")}</p>
          </div>
          <div className={classes.eagleTradeColumn}>
            <p className={classes.eagleTradeTableLabel}>{t("instruments")}</p>
            <p className={classes.eagleTradeTableText}>{t("fiat_curr")}</p>
          </div>
          <div
            className={[
              classes.eagleTradeColumn,
              classes.eagleTradeColumnBlack,
            ].join(" ")}
          >
            <p className={classes.eagleTradeTableLabel}>{t("margin_call")}</p>
            <p className={classes.eagleTradeTableText}>{t("respectively")}</p>
          </div>
          <div className={classes.linkWrap}>
            <a href={`https://client.wealth-wise.one/signup?lang=${lang}`} className={classes.eagleTradeLink}>
              {t("start_trading")}!
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EagleTrade;
