import React from "react";
import classes from "./Trade.module.scss";
import img from "../../../assets/img/affiliates/7/1.png";
import {useTranslation} from "react-i18next";
const Trade = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.trade}>
      <div className="container">
        <div className={classes.tradeBody}>
          <div className={classes.tradeRow}>
            <div className={classes.tradeRowLeft}>
              <img src={img} alt="" />
            </div>
            <div className={classes.tradeRowRight}>
              <div
                className={[classes.tradeRowRightTitle, "simpleTitle"].join(
                  " "
                )}
              >
                <span>{t('tools.trade_title')}</span>
              </div>
              <div className={classes.tradeRowRightSubtitle}>
                {t('tools.our_platform_text')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trade;
