import AffiliatesPage from "../../pages/AffiliatesPage/AffiliatesPage";
import HomePage from "../../pages/HomePage/HomePage";
import CompanyPage from "../../pages/CompanyPage/CompanyPage";
import ToolsPage from "../../pages/ToolsPage/ToolsPage";
import AboutPage from "../../pages/AboutPage/AboutPage";

export const routes = [
    { path: "*", element: HomePage },
    { path: "/affiliates", element: AffiliatesPage },
    { path: "/company", element: CompanyPage },
    { path: "/tools", element: ToolsPage },
    { path: "/about", element: AboutPage },
]

export const headerRoutes = [
    { path: "/tools", text: "tools_platform" },
    { path: "/affiliates", text: "affiliates_partners" },
    { path: "/about", text: "about_us" },
    { path: "/company", text: "company_support1" },
]

