import get_started from '../assets/img/get_started.png';
// main
import star from "../assets/img/Index/star.svg";
import bannerImg from "../assets/img/Index/banner-img.png";
// tools
import img from '../assets/img/Tools/banner.png';
import gs from '../assets/img/get_started.png';
// affiliates
import bg from "../assets/img/affiliates/1/1_mob.png";
import bg_001 from '../assets/img/affiliates/1/1.png';
// company support
import img22 from "../assets/img/would/1/1.png";


export const images = [
    get_started,
    star,
    bannerImg,
    img,
    gs,
    bg,
    bg_001,
    img22,
];