import React, { useEffect } from "react";
import First from "../../components/AffiliatesPageComponents/First/First";
import Levels from "../../components/AffiliatesPageComponents/Levels/Levels";
import Popular from "../../components/AffiliatesPageComponents/Popular/Popular";
import Hit from "../../components/AffiliatesPageComponents/Hit/Hit";
import Pack from "../../components/AffiliatesPageComponents/Pack/Pack";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";
import Become from "../../components/AffiliatesPageComponents/Become/Become";
import Trade from "../../components/AffiliatesPageComponents/Trade/Trade";

const AffiliatesPage = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);
  return (
    <div>
      <Header />
      <First />
      <Levels />
      <Popular />
      <Hit />
      <Pack />
      <Become />
      <Trade />
      <Footer />
    </div>
  );
};

export default AffiliatesPage;
