import React, { useEffect } from "react";
import HowToStart from "../../components/About/HowToStart/HowToStart";
import TradersAround from "../../components/About/TradersAround/TradersAround";
import WhyUs from "../../components/About/WhyUs/WhyUs";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";

const AboutPage = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);
    return (
        <div className="ovf-hidden">
            <Header />
            <WhyUs />
            <TradersAround />
            <HowToStart />
            <Footer />
        </div>
    );
};

export default AboutPage;
