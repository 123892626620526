import React from "react";
import classes from './TradeBenefit.module.scss'
import tradeBenefit from "../../../assets/img/Index/trade-benefit-img.png";
import {useTranslation} from "react-i18next";

const TradeBenefit = () => {
  const { t } = useTranslation();
  return (
    <div className='container'>
      <div className={classes.tradeBenefitRow}>
        <div className={classes.col5}>
          <img src={tradeBenefit} alt="" className="img-fluid"/>
        </div>
        <div className={[classes.col7, classes.tradeBenefitColWrap].join(' ')}>
          <div className={classes.tradeBenefitColWrap}>
            <h4 className={classes.tradeBenefitTitle}>{t('trade_benefit_title')}</h4>
            <p className={classes.tradeBenefitText}>{t('trade_benefit_text')}</p>
            <p className={classes.tradeBenefitTextWhite}>{t('trade_benefit_white_text')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TradeBenefit;
