import React, { useEffect } from "react";
import Would from "../../components/CompanyPageComponents/Would/Would";
import Currency from "../../components/CompanyPageComponents/Currency/Currency";
import Trade from "../../components/CompanyPageComponents/Trade/Trade";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";

const CompanyPage = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);
  return (
    <div>
      <Header />
      <Would />
      <Currency />
      <Trade />
      <Footer />
    </div>
  );
};

export default CompanyPage;
