import React from "react";
import classes from './HowToStart.module.scss';
import {Trans, useTranslation} from "react-i18next";

const HowToStart = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className={classes.howToStart}>
                <div className={[classes.header, 'simpleTitle'].join(' ')}>
                    <span>{t('how_to_start_title')}</span>
                </div>
                <div className={classes.text}>
                    {t('how_to_start_text')}
                </div>
                <div className={classes.content}>
                    <div className={classes.str}></div>
                    <div className={classes.contentItem}>
                        <div className={[classes.circle, classes.circle1].join(' ')}>1</div>
                        <div className={classes.contentItemTextTop}>{t('sign_now')}</div>
                        <div className={classes.contentItemTextBottom}></div>
                    </div>
                    <div className={classes.contentItem}>
                        <div className={[classes.circle, classes.circle2].join(' ')}>2</div>
                        <div className={classes.contentItemTextTop}></div>
                        <div className={classes.contentItemTextBottom}>
                            <Trans i18nKey="how_to_start_text2" components={{br: <br />}} />
                        </div>
                    </div>
                    <div className={classes.contentItem}>
                        <div className={[classes.circle, classes.circle3].join(' ')}>3</div>
                        <div className={classes.contentItemTextTop}>{t('make_deposit')}</div>
                        <div className={classes.contentItemTextBottom}></div>
                    </div>
                    <div className={classes.contentItem}>
                        <div className={[classes.circle, classes.circle4].join(' ')}>4</div>
                        <div className={classes.contentItemTextTop}></div>
                        <div className={classes.contentItemTextBottom}>
                            <Trans i18nKey="choose_payment" components={{br: <br />}} />
                        </div>
                    </div>
                    <div className={classes.contentItem}>
                        <div className={[classes.circle, classes.circle5].join(' ')}>5</div>
                        <div className={classes.contentItemTextTop}>{t('start_trading_session')}</div>
                        <div className={classes.contentItemTextBottom}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowToStart;
