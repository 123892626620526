import React from "react";
import classes from './Straight.module.scss';
import img from '../../../assets/img/Tools/straight.png';
import {Trans, useTranslation} from "react-i18next";

const Straight = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className={classes.streight}>
                <div className={classes.image}>
                    <img className="no-select" src={img} alt='' />
                </div>
                <div className={classes.content}>
                    <div className={classes.header}>
                        <Trans i18nKey="tools.straight_title" components={{span: <span></span>}} />
                    </div>
                    <div className={classes.text}>
                        <div>
                            {t('tools.straight_text_1')}
                        </div>
                        <div className={classes.par}>
                            {t('tools.straight_text_2')}
                        </div>
                        <div className={classes.par}>
                            {t('tools.straight_text_3')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Straight;
